import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { Auth } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { from, lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { AppUtil, SessionService } from "@cai-common";
import { ExpirableStorage, environment } from "@cai-common";
import { datadogRum } from "@datadog/browser-rum";

declare let window: any;

@Injectable()
export class AppInitService {
  constructor (private readonly sessionService: SessionService) {}

  public init () {
    const appConfigPath = AppUtil.getAppConfigPath(window.location.href);
    return lastValueFrom(
      from(
        fetch(appConfigPath).then(function (response) {
          return response.json();
        }),
      ).pipe(
        map((config) => {
          window.config = config;
          return config;
        }),
      ),
    ).then(() => {
      this.initDatadog();
      this.initAmplify();
    });
  }

  initDatadog () {
    if (environment.datadog) {
      datadogRum.init({
        "applicationId": "c27f5bc8-cc63-4893-b7f1-e880aa9a98d5",
        "clientToken": "pube00170a9dc04a30546454a1dc943a196",
        "site": "us3.datadoghq.com",
        "service": "cargoadmin",
        "version": environment.version,
        "enableExperimentalFeatures": ["clickmap", "feature_flags"],
        "env": environment.name,
        "sessionSampleRate": 100,
        "sessionReplaySampleRate": 100,
        "trackResources": true,
        "trackLongTasks": true,
        "trackUserInteractions": true,
        "proxy": "https://rum.cargoai.co",
        "defaultPrivacyLevel": "allow",
        "beforeSend": (event) => {
          event.view.url = AppUtil.modifyUrl(event.view.url);
          return true;
        },
      });
      datadogRum.startSessionReplayRecording();
    }
  }

  initAmplify () {
    const rememberMe = this.sessionService.getRememberMeLogin(),
     config = {
      "Auth": {
        "region": environment.amplify.auth.region,
        "userPoolId": environment.amplify.auth.userPoolId,
        "userPoolWebClientId": environment.amplify.auth.userPoolWebClientId,
        "mandatorySignIn": true,
        "storage": rememberMe ? localStorage : ExpirableStorage,
        "authenticationFlowType": "CUSTOM_AUTH",
        "oauth": environment.amplify.auth?.oauth,
      },
      "API": {
        "graphql_headers": async () => {
          try {
            return {
              "Authorization": `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          } catch {
            return null;
          }
        },
        "endpoints": environment.amplify.APIs.map((API) => ({
            "name": API.name,
            "endpoint": API.endpoint,
            "custom_header": async () => {
              try {
                return {
                  "Authorization": `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
                };
              } catch {
                return null;
              }
            },
          })),
        "aws_appsync_graphqlEndpoint":
          environment.amplify.aws_appsync_graphqlEndpoint,
        "aws_appsync_region": environment.amplify.aws_appsync_region,
        "aws_appsync_authenticationType":
          environment.amplify.aws_appsync_authenticationType,
        "aws_appsync_apiKey": environment.amplify.aws_appsync_apiKey,
      },
    };

    Amplify.configure(config);
    Auth.configure(config);
    API.configure(config);
  }
}
