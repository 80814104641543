import { mergeMap, Subscription } from "rxjs";
// Angular
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Injector,
} from "@angular/core";
import { NavigationEnd, Router, ActivatedRoute } from "@angular/router";
// idle check
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { filter, map } from "rxjs/operators";
import {
  environment,
  LayoutConfigService,
  RegisterService,
  RouterService,
  SeoService,
  SessionService,
  SplashScreenService,
  SUPPORTED_LANGUAGES,
} from "@cai-common";

const LOCAL_HOST = "//localhost",
 AUTH_URL = "/auth",
 REGISTER_URL = "/register",
 ERROR = "/error";

@Component({
  "selector": "div[kt-root]",
  "templateUrl": "./app.component.html",
  "styleUrls": ["./app.component.scss"],
  "changeDetection": ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = "Metronic";
  loader: boolean;
  hasNewVersion = false;
  sessionService: SessionService;
  registerService: RegisterService;
  private seoService: SeoService;
  splashScreenService: SplashScreenService;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  /**
   * Component constructor
   *
   * @param router: Router
   * @param layoutConfigService: LayoutCongifService
   * @param splashScreenService: SplashScreenService
   */
  constructor (
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    private idle: Idle,
    private readonly cdr: ChangeDetectorRef,
    private readonly routerSvc: RouterService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly injector: Injector,
  ) {
    this.registerService = injector.get<RegisterService>(RegisterService);
    this.sessionService = injector.get<SessionService>(SessionService);
    this.seoService = injector.get<SeoService>(SeoService);
    this.splashScreenService =
      injector.get<SplashScreenService>(SplashScreenService);
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit (): void {
    this.routerSvc.checkStorage();
    // enable/disable loader
    this.loader = this.layoutConfigService.getConfig("loader.enabled");
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          !event.url.includes(AUTH_URL) &&
          !event.url.includes(REGISTER_URL) &&
          !event.url.includes(ERROR)
        ) {
          this.translateApp(event?.url);
        }
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add("kt-page--loaded");
        }, 0);
      }
    });
    this.unsubscribe.push(routerSubscription);

    // Idle configuration
    this.configureIdle(this.idle);

    this.checkEnvironmentVersion();

    this.initializeSeo();
  }

  async translateApp (url: string) {
    const ALLOWED_LANGUAGES = !environment?.features?.newLanguages
      ? ["fr", "de", "es", "ja", "ko", "pt", "it", "pl"]
      : SUPPORTED_LANGUAGES,
     checkUrlLanguage = ALLOWED_LANGUAGES.filter(
      (language) => window.location.href?.indexOf(`/${language}/`) >= 0,
    ),
     isLocalHost = window.location.href.indexOf(LOCAL_HOST) >= 0;
    if (checkUrlLanguage?.length || isLocalHost) {
      return;
    } else {
      const token = this.activatedRoute.snapshot.paramMap.get("token"),
       storedUser = this.sessionService.getCurrentUser(),
       user = storedUser
        ? storedUser
        : await this.registerService.getUser(token);
      if (user.language === "en" || !user?.language) {
        return;
      }
      const new_route = `/${user.language}${url}`;
      window.location.replace(new_route);
    }
  }

  /**
   * On Destroy
   */
  ngOnDestroy () {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  checkEnvironmentVersion () {
    const fetchInterval = setInterval(
      () => {
        fetch("assets/environment/app-config.json")
          .then(async (response) => {
            const env = await response.json();
            this.hasNewVersion = env.version !== environment.version;
            this.cdr.detectChanges();
            clearInterval(fetchInterval);
          })
          .catch((err) => {
            console.warn("unable to fetch app config", err, " ...retrying");
            clearInterval(fetchInterval);
            this.checkEnvironmentVersion();
          });
      },
      1000 * 60 * 10,
    );
  }

  onReloadClick () {
    window.location.reload();
  }

  configureIdle (idle: Idle) {
    idle.setIdle(3570); // set to 59.5 minutes
    idle.setTimeout(30); // give user a 30second window before refreshing the duration
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeout.subscribe(() => {
      idle.watch(); // reset the watch before refresh
      window.location.reload();
    });

    idle.watch();
  }

  // copied from https://stackoverflow.com/questions/48330535/dynamically-add-meta-description-based-on-route-in-angular
  private initializeSeo (): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {route = route.firstChild;}
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data),
      )
      .subscribe((event) => {
        this.seoService.updateDescription(event.description);
      });
  }
}
