import { ApplicationProperties } from "@cai-common";
import { AuthenticationMethodEnum, UserTypeEnum } from "@cai-services";
import { SuperAdminMenuConfig } from "./config/superadmin-menu.config";

export class AppProperties implements ApplicationProperties {
  name = "cargoadmin";
  logo = "/assets/media/logos/cargoadmin.png";

  menu = [
    {
      "userType": UserTypeEnum.ADMIN,
      "authenticationMethods": [
        AuthenticationMethodEnum.COGNITO,
        AuthenticationMethodEnum.DEV,
        AuthenticationMethodEnum.ANONYMOUS,
        AuthenticationMethodEnum.SYSTEM,
      ],
      "defaultPage": "dashboard/searches",
      "config": new SuperAdminMenuConfig(),
    },
  ];
}
