export class SuperAdminMenuConfig {
  public defaults: any = {
    "header": {
      "self": {},
      "items": [
        {
          "title": "Dashboard",
          "root": true,
          "alignment": "left",
          "toggle": "click",
          "page": "/dashboard/searches",
        },
        {
          "title": "User Management",
          "root": true,
          "alignment": "left",
          "toggle": "click",
          "submenu": [
            {
              "title": "Users",
              "page": "/user-management/users",
            },
            {
              "title": "CargoINTEL Users",
              "page": "/user-management/cargo-intel-users",
            },
            {
              "title": "Permission Sets",
              "page": "/user-management/permission-sets",
            },
            {
              "title": "Offices",
              "page": "/user-management/offices",
            },
          ],
        },
        {
          "title": "User",
          "root": true,
          "alignment": "left",
          "toggle": "click",
          "submenu": [
            {
              "title": "Admin Users",
              "page": "/user/admin-page",
            },
            {
              "title": "Airline Users",
              "page": "/user/airline-users",
            },
            {
              "title": "Airline Company",
              "page": "/user/airline-company",
            },
            {
              "title": "GHAs",
              "page": "/user/ghas",
            },
            {
              "title": "Company",
              "page": "/user/company",
            },
            {
              "title": "Feature",
              "page": "/user/feature",
            },
            {
              "title": "General Conditions",
              "page": "/user/general-conditions",
            },
          ],
        },
        {
          "title": "Configuration",
          "root": true,
          "alignment": "left",
          "toggle": "click",
          "submenu": [
            {
              "title": "Frontend",
              "page": "/admin/frontend",
            },
            {
              "title": "Provider",
              "page": "/admin/provider",
            },
            {
              "title": "Airline",
              "page": "/admin/airline",
            },
            {
              "title": "Partner Integration",
              "page": "/admin/partner-integration",
            },
            {
              "title": "Upload schedule",
              "page": "/admin/scheduler",
            },
            {
              "title": "Upload rate",
              "page": "/admin/rate",
            },
            {
              "title": "Upload contact",
              "page": "/admin/contact",
            },
            {
              "title": "Partner Export Services",
              "page": "/admin/partner-export",
            },
          ],
        },
        {
          "title": "Wallet",
          "root": true,
          "alignment": "left",
          "toggle": "click",
          "submenu": [
            {
              "title": "Providers",
              "page": "/wallet/providers",
            },
            {
              "title": "Wallets",
              "page": "/wallet/wallets",
            },
            {
              "title": "Airline Invoices",
              "page": "/wallet/invoices",
            },
            {
              "title": "Invoice Items",
              "page": "/wallet/invoice-items",
            },
            {
              "title": "Payouts",
              "page": "/wallet/payouts",
            },
          ],
        },
        {
          "title": "CargoGate",
          "root": true,
          "alignment": "left",
          "toggle": "click",
          "submenu": [
            {
              "title": "CargoGates",
              "page": "/cargogate/cargogates",
            },
          ],
        },
      ],
    },
  };

  public get menuItems (): any {
    return this.defaults;
  }
}
