import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  BrandIconUtil,
  canDeactivateGuard,
  AdminGuard,
  BaseComponent,
  ErrorPageComponent,
} from "@cai-common";

const routes: Routes = [
  {
    "path": "auth",
    "loadChildren": () =>
      import("../../../cai-common/src/lib/components/auth/auth.module").then(
        (m) => m.AuthModule
      ),
  },
  {
    "path": "",
    "redirectTo": "auth/login",
    "pathMatch": "full",
    "title": `${BrandIconUtil.fetchDomainTitle()}`,
  },
  {
    "path": "",
    "component": BaseComponent,
    "children": [
      {
        "path": "admin",
        "loadChildren": () =>
          import("./pages/configuration/admin.module").then(
            (m) => m.AdminModule
          ),
        "canActivate": [AdminGuard],
      },
      {
        "path": "user-management",
        "loadChildren": () =>
          import("./pages/user-management/user-management.module").then(
            (m) => m.UserManagementModule
          ),
        "canActivate": [AdminGuard],
      },
      {
        "path": "user",
        "loadChildren": () =>
          import("./pages/user/user-pages.module").then(
            (m) => m.UserPageModule
          ),
        "canActivate": [AdminGuard],
      },
      {
        "path": "wallet",
        "loadChildren": () =>
          import("./pages/wallet/wallet.module").then((m) => m.WalletModule),
        "canActivate": [AdminGuard],
      },
      {
        "path": "cargogate",
        "loadChildren": () =>
          import("./pages/cargogate/cargogate.module").then(
            (m) => m.CargogateModule
          ),
        "canActivate": [AdminGuard],
      },
      {
        "path": "dashboard",
        "loadChildren": () =>
          import("./pages/search-dashboard/search-dashboard.module").then(
            (m) => m.SearchDashboardModule
          ),
        "canActivate": [AdminGuard],
      },
      {
        "path": "error/404",
        "component": ErrorPageComponent,
        "data": {
          "type": "error-v6",
          "code": 404,
          "title": "404... Page not found",
          "desc": "Page not found",
        },
      },
      {
        "path": "error/403",
        "component": ErrorPageComponent,
        "data": {
          "type": "error-v6",
          "code": 403,
          "title": "403... Access forbidden",
          "desc": "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
        },
      },
      //path to forwarder (close then)
    ],
  },
  { "path": "**", "redirectTo": "error/404", "pathMatch": "full" },
  { "path": "error/**", "redirectTo": "error/404", "pathMatch": "full" },
  { "path": "auth/**", "redirectTo": "error/404", "pathMatch": "full" },
  { "path": "**/**", "redirectTo": "error/404", "pathMatch": "full" },
];

@NgModule({
  "imports": [RouterModule.forRoot(routes)],
  "exports": [RouterModule],
  "providers": [canDeactivateGuard, AdminGuard],
})
export class AppRoutingModule {}
